import { fetchUtils } from "react-admin";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    const { token } = JSON.parse(localStorage.getItem("rk_auth"));
    options.credentials = "include";
    options.headers.set("X-Authorization", `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

export default httpClient;

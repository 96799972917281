import React, { useEffect, useState } from "react";
import {
    Button,
    IconButton,
    Menu,
    Tooltip,
    MenuItem,
    ListItemIcon,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import styles from "./RaakUserMenu.module.css";
import { MenuItemLink, useLogout, useTranslate } from "react-admin";
import ExitIcon from "@material-ui/icons/PowerSettingsNew";
import SettingsIcon from "@material-ui/icons/Settings";
import { useProfile } from "../Profile/ProfileEdit";

const defaultIcon = <AccountCircle className={styles.avatar} />;

const AnchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
};

const TransformOrigin = {
    vertical: "top",
    horizontal: "right",
};

const RaakUserMenu = ({ label = "ra.auth.user_menu", icon = defaultIcon }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const translate = useTranslate();
    const { profileVersion } = useProfile();
    const logout = useLogout();

    const [identity, setIdentity] = useState(null);

    useEffect(() => {
        const identity = JSON.parse(localStorage.getItem("rk_auth"));
        if (identity) {
            setIdentity(identity);
        }
    }, [profileVersion]);

    const open = Boolean(anchorEl);

    const handleMenu = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleClick = () => logout();

    return (
        <div className={styles.user} key={profileVersion}>
            {identity && identity?.full_name ? (
                <Button
                    aria-label={label && translate(label, { _: label })}
                    className={styles.userButton}
                    color="inherit"
                    startIcon={icon}
                    onClick={handleMenu}
                >
                    <div className={styles.userButtonLabel}>
                        {identity.company_name && (
                            <h6 className={styles.companyTitle}>
                                {identity.company_name}
                            </h6>
                        )}
                        <div className={styles.userName}>
                            {identity.full_name}
                        </div>
                    </div>
                </Button>
            ) : (
                <Tooltip title={label && translate(label, { _: label })}>
                    <IconButton
                        aria-label={label && translate(label, { _: label })}
                        aria-owns={open ? "menu-appbar" : null}
                        aria-haspopup={true}
                        color="inherit"
                        onClick={handleMenu}
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
            )}
            <Menu
                id="menu-appbar"
                disableScrollLock
                anchorEl={anchorEl}
                anchorOrigin={AnchorOrigin}
                transformOrigin={TransformOrigin}
                getContentAnchorEl={null}
                open={open}
                onClose={handleClose}
            >
                <MenuItemLink
                    className={styles.menuItem}
                    to="/my-profile"
                    primaryText={translate("ra.user_menu.profile")}
                    leftIcon={<SettingsIcon />}
                />
                <MenuItem onClick={handleClick} className={styles.menuItem}>
                    <ListItemIcon>
                        <ExitIcon />
                    </ListItemIcon>
                    {translate("ra.user_menu.logout")}
                </MenuItem>
            </Menu>
        </div>
    );
};

export default RaakUserMenu;

import { Field, Form } from "react-final-form";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { resetNotification, useNotify, useTranslate } from "react-admin";
import React, { useState } from "react";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import apiService from "../apiService";
import { makeStyles } from "../Theme/PortalThemeProvider";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import AuthLayout from "../Layout/AuthLayout";
import { useAppContext } from "../appProvider";
import InputField from "../Components/Fields/InputField";
import clsx from "clsx";
import Aside from "../Components/Aside";

const useStyles = makeStyles((theme) => ({
    hidden: {
        display: "none",
    },
    input: {
        ...theme.components?.PortalInput,
        width: "100%",
        "&:first-child": {
            marginTop: theme.spacing(3),
            [theme.breakpoints.up("md")]: {
                marginTop: theme.spacing(4),
            },
            [theme.breakpoints.up("xl")]: {
                marginTop: theme.spacing(5),
            },
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
    linkPrimary: {
        ...theme.components?.PortalLinkPrimary,
    },
    linkSecondary: {
        ...theme.components?.PortalLinkSecondary,
    },
    button: {
        ...theme.components?.PortalButton,
    },
}));

const ResetPasswordPage = (props) => {
    const { search } = useLocation();
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const dispatch = useDispatch();
    const history = useHistory();
    const settings = useAppContext();

    const handleSubmit = (data) => {
        const payload = { domain: settings?.domain, ...data };
        setLoading(true);
        setIsSubmitted(false);
        setError(null);
        apiService
            .resetPassword(payload)
            .then((res) => {
                dispatch(resetNotification());
                setLoading(false);
                setIsSubmitted(true);
                notify(res.data.message, "success");
                history.push("/");
            })
            .catch((error) => {
                console.log("res", error);
                console.log(error.message);
                setLoading(false);
                setError(error.message);
            });
    };

    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = translate("ra.validation.required");
        }
        return errors;
    };

    const content = (
        <>
            {!isSubmitted && (
                <Form
                    initialValues={{
                        token: new URLSearchParams(search).get("token"),
                        email: new URLSearchParams(search).get("email"),
                    }}
                    onSubmit={handleSubmit}
                    className={classes.form}
                    validate={validate}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Field
                                required
                                fullWidth
                                id="token"
                                name="token"
                                type="hidden"
                                component={InputField}
                                className={classes.hidden}
                                inputProps={settings?.theme?.props?.PortalInput}
                            />
                            <Field
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={translate("ra.auth.email")}
                                name="email"
                                disabled={loading}
                                autoComplete="email"
                                component={InputField}
                                className={classes.input}
                                inputProps={settings?.theme?.props?.PortalInput}
                            />
                            <Field
                                margin="normal"
                                type="password"
                                required
                                fullWidth
                                id="password"
                                label={translate("ra.auth.new_password")}
                                name="password"
                                disabled={loading}
                                component={InputField}
                                className={classes.input}
                                inputProps={settings?.theme?.props?.PortalInput}
                            />
                            <Field
                                margin="normal"
                                type="password"
                                required
                                fullWidth
                                id="password_confirmation"
                                label={translate(
                                    "ra.auth.new_password_confirm"
                                )}
                                name="password_confirmation"
                                disabled={loading}
                                component={InputField}
                                className={classes.input}
                                inputProps={settings?.theme?.props?.PortalInput}
                            />
                            {error && (
                                <Box mt={2}>
                                    <Alert variant="outlined" severity="error">
                                        {error}
                                    </Alert>
                                </Box>
                            )}
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Grid item xs>
                                    <Button
                                        type="submit"
                                        disabled={loading}
                                        className={classes.button}
                                        startIcon={
                                            loading ? (
                                                <CircularProgress
                                                    size={18}
                                                    thickness={2}
                                                />
                                            ) : null
                                        }
                                    >
                                        {loading
                                            ? translate("ra.auth.processing")
                                            : translate(
                                                  "ra.auth.reset_password"
                                              )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
            )}
            {isSubmitted && (
                <Box mt={2}>
                    <Typography variant="body1" color="textSecondary">
                        {translate("ra.auth.reset_password_message")}
                    </Typography>
                </Box>
            )}
            <Link
                component={RouterLink}
                to="/login"
                className={clsx([classes.linkSecondary], {
                    ["disabled"]: loading,
                })}
            >
                {translate("ra.auth.back_to_login_page")}
            </Link>
        </>
    );

    return (
        <AuthLayout
            content={content}
            aside={
                <Aside
                    image={settings?.resetPasswordImage}
                    video={settings?.resetPasswordVideo}
                />
            }
            title={translate("ra.auth.reset_password")}
        />
    );
};

export default ResetPasswordPage;

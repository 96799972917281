import React, { useState } from "react";
import { makeStyles } from "../Theme/PortalThemeProvider";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useRedirect, useTranslate } from "react-admin";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import AuthLayout from "../Layout/AuthLayout";
import { Field, Form } from "react-final-form";
import Alert from "@material-ui/lab/Alert";
import apiService from "../apiService";
import { useLocation } from "react-router-dom";
import InputField from "../Components/Fields/InputField";
import Aside from "../Components/Aside";
import { useAppContext } from "../appProvider";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    input: {
        ...theme.components?.PortalInput,
        width: "100%",
        "&:first-child": {
            marginTop: theme.spacing(3),
            [theme.breakpoints.up("md")]: {
                marginTop: theme.spacing(4),
            },
            [theme.breakpoints.up("xl")]: {
                marginTop: theme.spacing(5),
            },
        },
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
    linkPrimary: {
        ...theme.components?.PortalLinkPrimary,
    },
    linkSecondary: {
        ...theme.components?.PortalLinkSecondary,
    },
    button: {
        ...theme.components?.PortalButton,
    },
}));

const ActivatePage = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const translate = useTranslate();
    const redirect = useRedirect();
    const settings = useAppContext();
    const search = useLocation().search;
    const code = new URLSearchParams(search).get("code") || "";

    const onSubmit = async (payload) => {
        setLoading(true);
        setError(false);
        try {
            await apiService.activateAccount(payload);
            setIsSubmitted(true);
            redirect("/");
        } catch (err) {
            setLoading(false);
            setError(true);
            const errors = {};
            if (err.errors.activation_code) {
                errors.activation_code = err.errors.activation_code[0];
            }
            if (err.errors.email) {
                errors.email = err.errors.email[0];
            }
            return errors;
        }
    };

    const validate = (values) => {
        const errors = {};
        if (!values.activation_code) {
            errors.activation_code = translate("ra.validation.required");
        }
        if (!values.first_name) {
            errors.first_name = translate("ra.validation.required");
        }
        if (!values.last_name) {
            errors.last_name = translate("ra.validation.required");
        }
        if (!values.email) {
            errors.email = translate("ra.validation.required");
        }
        if (!values.password) {
            errors.password = translate("ra.validation.required");
        }
        if (!values.password_confirmation) {
            errors.password_confirmation = translate("ra.validation.required");
        }
        return errors;
    };

    const content = (
        <>
            {!isSubmitted && (
                <>
                    <Box mt={4} mb={4}>
                        <Typography variant="body1" color="textSecondary">
                            {translate("ra.auth.activate.content")}
                        </Typography>
                    </Box>
                    <Form
                        onSubmit={onSubmit}
                        className={classes.form}
                        validate={validate}
                        render={({ handleSubmit, submitError }) => (
                            <form onSubmit={handleSubmit}>
                                <Field
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="activation_code"
                                    label={translate(
                                        "ra.fields.activation_code"
                                    )}
                                    name="activation_code"
                                    disabled={loading}
                                    component={InputField}
                                    initialValue={code}
                                    className={classes.input}
                                    inputProps={
                                        settings?.theme?.props?.PortalInput
                                    }
                                />
                                <Field
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="first_name"
                                    label={translate("ra.fields.first_name")}
                                    name="first_name"
                                    disabled={loading}
                                    autoComplete="first_name"
                                    component={InputField}
                                    className={classes.input}
                                    inputProps={
                                        settings?.theme?.props?.PortalInput
                                    }
                                />
                                <Field
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="last_name"
                                    label={translate("ra.fields.last_name")}
                                    name="last_name"
                                    disabled={loading}
                                    autoComplete="last_name"
                                    component={InputField}
                                    className={classes.input}
                                    inputProps={
                                        settings?.theme?.props?.PortalInput
                                    }
                                />
                                <Field
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={translate("ra.fields.email")}
                                    name="email"
                                    disabled={loading}
                                    autoComplete="email"
                                    component={InputField}
                                    className={classes.input}
                                    inputProps={
                                        settings?.theme?.props?.PortalInput
                                    }
                                />
                                <Field
                                    margin="normal"
                                    type="password"
                                    required
                                    fullWidth
                                    id="password"
                                    label={translate("ra.fields.password")}
                                    name="password"
                                    disabled={loading}
                                    component={InputField}
                                    className={classes.input}
                                    inputProps={
                                        settings?.theme?.props?.PortalInput
                                    }
                                />
                                <Field
                                    margin="normal"
                                    type="password"
                                    required
                                    fullWidth
                                    id="password_confirmation"
                                    label={translate(
                                        "ra.fields.confirm_password"
                                    )}
                                    name="password_confirmation"
                                    disabled={loading}
                                    component={InputField}
                                    className={classes.input}
                                    inputProps={
                                        settings?.theme?.props?.PortalInput
                                    }
                                />
                                {error && (
                                    <Box mt={2}>
                                        <Alert
                                            variant="outlined"
                                            severity="error"
                                        >
                                            {translate(
                                                "ra.auth.activation_error"
                                            )}
                                        </Alert>
                                    </Box>
                                )}
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item>
                                        <Link
                                            component={RouterLink}
                                            to="/login"
                                            className={classes.linkSecondary}
                                        >
                                            {translate("ra.auth.activate.back")}
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            disabled={loading}
                                            className={classes.button}
                                            startIcon={
                                                loading ? (
                                                    <CircularProgress
                                                        size={18}
                                                        thickness={2}
                                                    />
                                                ) : null
                                            }
                                        >
                                            {loading
                                                ? translate(
                                                      "ra.auth.processing"
                                                  )
                                                : translate(
                                                      "ra.auth.activate.button"
                                                  )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    />
                </>
            )}
            {isSubmitted && (
                <>
                    <Box mt={4} mb={4}>
                        <Typography variant="body1" color="textSecondary">
                            {translate("ra.auth.activation_success")}
                        </Typography>
                    </Box>
                    <Link
                        component={RouterLink}
                        to="/login"
                        className={clsx([classes.linkSecondary], {
                            ["disabled"]: loading,
                        })}
                    >
                        {translate("ra.auth.back_to_login_page")}
                    </Link>
                </>
            )}
        </>
    );

    return (
        <AuthLayout
            content={content}
            aside={
                <Aside
                    image={settings?.activationPageImage}
                    video={settings?.activationPageVideo}
                />
            }
            title={translate("ra.auth.activate.title")}
        />
    );
};

export default ActivatePage;

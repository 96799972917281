import React, { useState } from "react";
import { useTranslate, useLogin } from "react-admin";
import { Field, Form } from "react-final-form";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Button, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AuthLayout from "../Layout/AuthLayout";
import { useAppContext } from "../appProvider";
import Aside from "../Components/Aside";
import InputField from "../Components/Fields/InputField";

const useStyles = makeStyles((theme) => {
    return {
        input: {
            ...theme.components?.PortalInput,
            width: "100%",
            "&:first-child": {
                marginTop: theme.spacing(3),
                [theme.breakpoints.up("md")]: {
                    marginTop: theme.spacing(4),
                },
                [theme.breakpoints.up("xl")]: {
                    marginTop: theme.spacing(5),
                },
            },
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(4),
        },
        linkPrimary: {
            ...theme.components?.PortalLinkPrimary,
        },
        linkSecondary: {
            ...theme.components?.PortalLinkSecondary,
        },
        button: {
            ...theme.components?.PortalButton,
        },
    };
});

const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const translate = useTranslate();
    const login = useLogin();
    const settings = useAppContext();
    const classes = useStyles();

    const onSubmit = (auth) => {
        const payload = { domain: settings?.domain, ...auth };
        setLoading(true);
        setError(null);
        login(
            payload,
            location.state ? location.state.nextPathname : "/"
        ).catch((error) => {
            setLoading(false);
            setError(error);
        });
    };

    const validateLogin = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = translate("ra.validation.required");
        }
        if (!values.password) {
            errors.password = translate("ra.validation.required");
        }
        return errors;
    };

    const content = (
        <Form
            onSubmit={onSubmit}
            className={classes.form}
            validate={validateLogin}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={translate("ra.auth.email")}
                        name="email"
                        disabled={loading}
                        autoComplete="email"
                        component={InputField}
                        className={classes.input}
                        inputProps={settings?.theme?.props?.PortalInput}
                    />
                    <Field
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        source="password"
                        label={translate("ra.auth.password")}
                        type="password"
                        id="password"
                        disabled={loading}
                        autoComplete="current-password"
                        component={InputField}
                        className={classes.input}
                        inputProps={settings?.theme?.props?.PortalInput}
                    />
                    {error && (
                        <Box mt={2}>
                            <Alert variant="outlined" severity="error">
                                {translate("ra.auth.sign_in_error")}
                            </Alert>
                        </Box>
                    )}
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Link
                                component={RouterLink}
                                to="register"
                                className={classes.linkPrimary}
                            >
                                {translate("ra.auth.register.title")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link
                                component={RouterLink}
                                to="activatie"
                                className={classes.linkPrimary}
                            >
                                {translate("ra.auth.activate.title")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                disabled={loading}
                                className={classes.button}
                                startIcon={
                                    loading ? (
                                        <CircularProgress
                                            size={18}
                                            thickness={2}
                                        />
                                    ) : null
                                }
                            >
                                {loading
                                    ? translate("ra.auth.processing")
                                    : translate("ra.auth.sign_in")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Link
                                component={RouterLink}
                                to="forgot-password"
                                className={classes.linkSecondary}
                            >
                                {translate("ra.auth.forgot_password")}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            )}
        />
    );

    return (
        <AuthLayout
            content={content}
            aside={
                <Aside
                    image={settings?.loginPageImage}
                    video={settings?.loginPageVideo}
                />
            }
            title={translate("ra.auth.sign_in")}
        />
    );
};

export default LoginPage;

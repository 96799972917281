import { Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "../Theme/PortalThemeProvider";
import { useAppContext } from "../appProvider";

const useStyles = makeStyles((theme) => ({
    text: {
        color: "#4C5C69",
        fontSize: theme.spacing(1.75),
    },
    link: {
        textDecoration: "none",
        color: "#4C5C69",
    },
}));

const Copyright = () => {
    const classes = useStyles();
    const settings = useAppContext();
    const text = settings?.copyright?.text || null;
    const url = settings?.copyright?.url || null;

    if (!(text && url)) return null;

    return (
        <Typography
            variant="body1"
            color="textSecondary"
            className={classes.text}
        >
            {"© "}
            {new Date().getFullYear()}{" "}
            <a
                className={classes.link}
                href={url}
                target="_blank"
                rel="noreferrer"
            >
                {text}
            </a>
        </Typography>
    );
};

export default Copyright;

import axios from "axios";

const PROVIDER_URI = process.env.REACT_APP_DATA_PROVIDER_URI || null;

const getApiHeaders = () => {
    const { token } = JSON.parse(localStorage.getItem("rk_auth"));

    return {
        Accept: "application/json",
        "X-Authorization": `Bearer ${token}`,
    };
};

const getQueryParams = (params = {}) => {
    const queryParams = Object.keys(params)
        ? Object.keys(params)
              .filter((key) => params[key])
              .map((key) => [key, params[key]].join("="))
              .join("&")
        : "";

    return queryParams ? `?${queryParams}` : "";
};

const apiService = {
    getSiteSettings: (site) => {
        if (!site) return {};
        try {
            const response = axios.get(
                PROVIDER_URI + `/portals/${site}/settings`
            );
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject("Failed to load site settings");
        }
    },

    getCustomerDashboardData: (params = {}) => {
        const headers = getApiHeaders();
        const query = getQueryParams(params);

        try {
            const response = axios.get(PROVIDER_URI + `/dashboard` + query, {
                headers: headers,
            });
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject("Failed to load dashboard overview data");
        }
    },

    getAdminDashboardData: (params = {}) => {
        const headers = getApiHeaders();
        const query = getQueryParams(params);

        try {
            const response = axios.get(
                PROVIDER_URI + `/admin-dashboard` + query,
                { headers: headers }
            );
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject("Failed to load dashboard overview data");
        }
    },

    getWeighingTicketsData: (params = {}) => {
        return Promise.all([
            apiService.getMaterialPricesData(params),
            apiService.getWeighingTicketsWeightData(params),
            apiService.getInvoiceStatisticsData(params),
        ])
            .then((res) => {
                return Promise.resolve(res);
            })
            .catch((err) => Promise.reject(err));
    },

    getMaterialPricesData: async (params = {}) => {
        const headers = getApiHeaders();
        const query = getQueryParams(params);

        try {
            const { data } = await axios.get(
                PROVIDER_URI + `/material-prices/overview` + query,
                { headers: headers }
            );
            return Promise.resolve(data);
        } catch (e) {
            return Promise.reject(
                "Failed to load material prices overview data"
            );
        }
    },

    getWeighingTicketsWeightData: async (params = {}) => {
        const headers = getApiHeaders();
        const query = getQueryParams(params);

        try {
            const { data } = await axios.get(
                PROVIDER_URI + `/weighing-tickets/overview` + query,
                { headers: headers }
            );
            return Promise.resolve(data);
        } catch (e) {
            return Promise.reject(
                "Failed to load weighing tickets overview data"
            );
        }
    },

    getInvoiceStatisticsData: async (params = {}) => {
        const headers = getApiHeaders();
        const query = getQueryParams(params);

        try {
            const { data } = await axios.get(
                PROVIDER_URI + `/invoices/chart-statistics` + query,
                { headers: headers }
            );
            return Promise.resolve(data);
        } catch (e) {
            return Promise.reject(
                "Failed to load invoice chart statistics data"
            );
        }
    },

    getMaterialCategoriesData: (params = {}) => {
        const headers = getApiHeaders();
        const query = getQueryParams(params);

        try {
            const response = axios.get(
                PROVIDER_URI + `/materials/categories` + query,
                { headers: headers }
            );
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject(
                "Failed to load material type categories data"
            );
        }
    },

    getMaterialNameSuggestions: (id) => {
        const headers = getApiHeaders();

        try {
            const response = axios.get(
                PROVIDER_URI + `/materials/${id}/name-suggestions`,
                { headers: headers }
            );
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject(
                "Failed to load material name suggestions data"
            );
        }
    },

    getInvoiceDateRange: () => {
        const headers = getApiHeaders();

        try {
            const response = axios.get(PROVIDER_URI + `/invoices/intervals`, {
                headers: headers,
            });
            return Promise.resolve(response);
        } catch (e) {
            return [];
        }
    },

    getWeighingTicketDateRange: () => {
        const headers = getApiHeaders();

        try {
            const response = axios.get(
                PROVIDER_URI + `/weighing-tickets/intervals`,
                { headers: headers }
            );
            return Promise.resolve(response);
        } catch (e) {
            return [];
        }
    },

    fetchData(baseUrl, id) {
        const headers = getApiHeaders();
        try {
            const response = axios.get(PROVIDER_URI + baseUrl + "/" + id, {
                headers: headers,
            });
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject(
                "Failed to load material type categories data"
            );
        }
    },

    sendResetPasswordLink(payload) {
        try {
            const response = axios.post(
                PROVIDER_URI + `/auth/password/email`,
                payload
            );
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject("Failed to send password reset link");
        }
    },

    resetPassword(payload) {
        try {
            const response = axios.post(
                PROVIDER_URI + `/auth/password/reset`,
                payload
            );
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject("Failed to reset password");
        }
    },
    async updateProfile(payload) {
        const headers = getApiHeaders();

        try {
            const response = await axios.post(
                PROVIDER_URI + `/profile`,
                payload,
                { headers: headers }
            );

            const auth = response.data;
            const data = JSON.parse(localStorage.getItem("rk_auth"));

            localStorage.setItem(
                "rk_auth",
                JSON.stringify({ ...data, ...auth })
            );

            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    async activateAccount(payload) {
        try {
            const response = await axios.post(
                PROVIDER_URI + `/auth/activate`,
                payload
            );
            return Promise.resolve(response.data);
        } catch (err) {
            // throw new Error("Failed to activate account");
            return Promise.reject(err.response.data);
        }
    },
};

export default apiService;

import {
    List,
    Datagrid,
    TextField,
    EmailField,
    ReferenceField,
    BooleanField,
    useTranslate,
    Pagination,
    TopToolbar,
    CreateButton,
    TextInput,
} from "react-admin";
import FormattedDateField from "../../Components/Fields/FormattedDateField";
import ResourceTitle from "../../Components/ResourceTitle";
import React from "react";
import { Card, CardHeader, Grid } from "@material-ui/core";
import EmptyList from "../../Components/EmptyList";

const UserPagination = (props) => (
    <Pagination rowsPerPageOptions={[25, 50, 75]} {...props} />
);

const UserList = (props) => {
    const translate = useTranslate();
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <ResourceTitle>
                    {translate("ra.resources.employees")}
                </ResourceTitle>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title={translate("ra.dashboard.overview")}
                        action={<CreateButton />}
                    />
                    <List
                        {...props}
                        title={<></>}
                        perPage={25}
                        pagination={<UserPagination />}
                        bulkActionButtons={false}
                        actions={false}
                        exporter={false}
                        empty={
                            <EmptyList
                                resourceName={translate(
                                    "ra.no_resource_found.users"
                                )}
                            />
                        }
                    >
                        <Datagrid rowClick="edit">
                            <BooleanField source="is_active" label="" />
                            <FormattedDateField
                                source="created_at"
                                label={translate("ra.fields.date_registered")}
                            />
                            <TextField
                                source="first_name"
                                label={translate("ra.fields.first_name")}
                            />
                            <TextField
                                source="last_name"
                                label={translate("ra.fields.last_name")}
                            />
                            <EmailField source="email" />
                        </Datagrid>
                    </List>
                </Card>
            </Grid>
        </Grid>
    );
};

export default UserList;

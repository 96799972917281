import { createContext, useContext, useLayoutEffect, useState } from "react";
import apiService from "./apiService";
import {
    useModal,
    ErrorMessage,
    LoadingSpinner,
} from "./Components/OverlayModal";

const AppContext = createContext(null);

export const useAppContext = () => {
    return useContext(AppContext);
};

const AppProvider = ({ children }) => {
    const [siteSettings, setSiteSettings] = useState(null);
    const { setContent } = useModal();

    const domain = window.location.hostname;

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    const site = params?.site || domain;

    useLayoutEffect(() => {
        const getSiteSettings = async () => {
            const { data } = await apiService.getSiteSettings(site);

            if (data?.settings)
                setSiteSettings({ domain: data?.domain, ...data?.settings });
        };

        setContent(LoadingSpinner);
        getSiteSettings().catch((err) => {
            setContent(ErrorMessage);
        });
    }, []);

    // Generate dynamic favicon from site settings
    const generateFavicons = (favicon = []) => {
        if (!favicon.length) return null;

        let headTitle = document.querySelector("head");

        return favicon.forEach((item) => {
            if (!item.href) return;

            let setFavicon = document.createElement("link");
            for (const [key, value] of Object.entries(item)) {
                setFavicon.setAttribute(key, value);
            }
            headTitle.appendChild(setFavicon);
        });
    };

    // Setting website title, favicon etc.
    useLayoutEffect(() => {
        if (siteSettings) {
            document.title = siteSettings?.name;
            generateFavicons(siteSettings?.favicon);
            setTimeout(() => {
                setContent(null);
            }, 2000);
        }
    }, [siteSettings]);

    return (
        <AppContext.Provider value={siteSettings}>
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;

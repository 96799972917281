import React, { useState, useEffect, useRef, useCallback } from "react";
import apiService from "../apiService";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import ResourceTitle from "../Components/ResourceTitle";
import DashboardOverviewChart from "../Components/DashboardOverviewChart";
import CardHeaderButton from "../Components/CardHeaderButton";
import { useTranslate } from "react-admin";
import { useHistory } from "react-router-dom";
import { makeStyles } from "../Theme/PortalThemeProvider";
import Price from "../Components/Price";
import PeriodSelector from "../Components/PeriodSelector";
import { DateTime, Interval } from "luxon";
import debounce from "lodash.debounce";
import EmptyList from "../Components/EmptyList";
import styles from "./DashboardCustomer.module.css";

// const formatter = new Intl.NumberFormat("nl-NL", {
//     minimumFractionDigits: 3, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
//     maximumFractionDigits: 3, // (causes 2500.99 to be printed as $2,501)
// });

const formatter = (options) => new Intl.NumberFormat("nl-NL", options);

const useStyles = makeStyles(() => ({
    tableRow: {
        "&:hover": {
            background: "#F8F8F8",
            cursor: "pointer",
        },
    },
}));

const DashboardCustomer = () => {
    const [overviewData, setOverviewData] = useState({});
    const [periodRange, setPeriodRange] = useState([]);
    const [selectedYears, setSelectedYears] = useState({
        first: "",
        second: "",
    });

    const classes = useStyles();
    const history = useHistory();
    const translate = useTranslate();

    const colors = ["#607787", "#8fa2af"];

    // Debounce the getResults function call
    const debouncedGetOverviewData = useCallback(
        debounce((years) => getOverviewData(years), 500),
        []
    );

    const getOverviewData = (years) => {
        return apiService
            .getCustomerDashboardData({ years: years.join(",") })
            .then((res) => setOverviewData(res.data));
    };

    const getPeriodRange = () => {
        return apiService.getInvoiceDateRange().then((res) => {
            if (res.status === 200) {
                // setQuarterRange(res.data);
                let interval = Interval.fromDateTimes(
                    DateTime.fromFormat(res.data.start, "yyyy"),
                    DateTime.fromFormat(res.data.end, "yyyy")
                );
                const values = [];
                let cursor = interval.start.startOf("year");
                while (cursor <= interval.end.endOf("year")) {
                    values.push({
                        label: cursor.year.toString(),
                        value: cursor.toFormat("yyyy"),
                    });
                    cursor = cursor.plus({ year: 1 });
                }
                setPeriodRange(values);
            }
        });
    };

    const handleChangePeriod = (begin, end) => {
        setSelectedYears({
            first: begin,
            second: end,
        });
    };

    useEffect(() => {
        if (selectedYears.first || selectedYears.second) {
            debouncedGetOverviewData(
                [selectedYears.first, selectedYears.second].filter(Boolean)
            );
        }
    }, [selectedYears]);

    useEffect(() => {
        if (periodRange.length) {
            setSelectedYears({
                first:
                    periodRange.length <= 2
                        ? periodRange[0].value
                        : periodRange[periodRange.length - 2].value,
                second: periodRange[periodRange.length - 1].value,
            });
        }
    }, [periodRange]);

    useEffect(() => {
        getPeriodRange();
    }, []);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <ResourceTitle>
                    {translate("ra.dashboard.dashboard")}
                </ResourceTitle>
            </Grid>
            <Grid item xs={12}>
                <Card className={styles.overview}>
                    <Box className={styles.overviewGraph}>
                        <CardHeader
                            title={translate("ra.dashboard.overview")}
                            action={
                                periodRange.length > 0 ? (
                                    <PeriodSelector
                                        range={periodRange}
                                        beginDate={selectedYears.first}
                                        endDate={selectedYears.second}
                                        onChange={handleChangePeriod}
                                    />
                                ) : (
                                    <></>
                                )
                            }
                        />
                        <CardContent style={{ paddingTop: 0 }}>
                            {overviewData.chart_data && (
                                <DashboardOverviewChart
                                    data={overviewData.chart_data?.map(
                                        (data) => {
                                            return Object.fromEntries(
                                                Object.entries(data).map(
                                                    ([key, value]) => [
                                                        key,
                                                        key === "date"
                                                            ? value
                                                            : value / 100,
                                                    ]
                                                )
                                            );
                                        }
                                    )}
                                />
                            )}
                        </CardContent>
                    </Box>
                    <Box className={styles.overviewStats}>
                        <CardContent
                            style={{
                                display: "flex",
                                flex: 1,
                                padding: 0,
                                fontWeight: 900,
                            }}
                        >
                            {overviewData.statistics_data?.total_income?.map(
                                (item, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            flex: 1,
                                            padding: "2rem",
                                            borderBottom: "solid 1px #e3e3e3",
                                            borderRight: "solid 1px #e3e3e3",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontWeight: 900,
                                                marginBottom: ".5rem",
                                            }}
                                        >
                                            {translate(
                                                "ra.statistics.total_revenue"
                                            )}
                                        </div>
                                        <div style={{ marginBottom: "1rem" }}>
                                            <span
                                                style={{
                                                    marginRight: "1rem",
                                                    display: "inline-block",
                                                    width: ".75rem",
                                                    height: ".75rem",
                                                    borderRadius: "50%",
                                                    background: colors[index],
                                                }}
                                            />
                                            <label
                                                htmlFor={item.year}
                                                style={{ fontWeight: 400 }}
                                            >
                                                {item.year}
                                            </label>
                                        </div>
                                        <Typography
                                            variant="h3"
                                            style={{ marginBottom: "1rem" }}
                                        >
                                            <Price
                                                options={{
                                                    style: "currency",
                                                    currency: "EUR",
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                }}
                                            >
                                                {item.total_income}
                                            </Price>
                                        </Typography>
                                    </div>
                                )
                            )}
                        </CardContent>
                        <CardContent
                            style={{
                                backgroundColor: "#f3f3f3",
                                backgroundImage:
                                    "linear-gradient(to right, rgba(0,0,0,.02), rgba(0,0,0,0) 1rem)",
                                borderTop: "solid 1px #e3e3e3",
                                padding: 0,
                                fontWeight: 900,
                                display: "flex",
                                flex: 1,
                            }}
                        >
                            {overviewData.statistics_data?.total_tonnage?.map(
                                (item, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            flex: 1,
                                            padding: "2rem",
                                            border: "solid 1px #e3e3e3",
                                            borderTop: "none",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontWeight: 900,
                                                marginBottom: ".5rem",
                                            }}
                                        >
                                            {translate(
                                                "ra.statistics.total_weight_kg"
                                            )}
                                        </div>
                                        <div style={{ marginBottom: "1rem" }}>
                                            <label
                                                htmlFor={item.year}
                                                style={{ fontWeight: 400 }}
                                            >
                                                {item.year}
                                            </label>
                                        </div>

                                        <Typography variant="h3">
                                            {formatter({
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            }).format(item.total_tonnage)}
                                        </Typography>
                                    </div>
                                )
                            )}
                        </CardContent>
                        <CardContent
                            style={{
                                display: "flex",
                                backgroundColor: "#f3f3f3",
                                backgroundImage:
                                    "linear-gradient(to right, rgba(0,0,0,.02), rgba(0,0,0,0) 1rem)",
                                borderTop: "solid 1px #e3e3e3",
                                padding: 0,
                                flex: 1,
                            }}
                        >
                            {overviewData.statistics_data?.total_emission_savings?.map(
                                (item, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            flex: 1,
                                            padding: "2rem",
                                            border: "solid 1px #e3e3e3",
                                            borderTop: "none",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontWeight: 900,
                                                marginBottom: ".5rem",
                                            }}
                                        >
                                            Bespaarde CO<sub>2</sub> (KG)
                                        </div>
                                        <div style={{ marginBottom: "1rem" }}>
                                            <label
                                                htmlFor={item.year}
                                                style={{ fontWeight: 400 }}
                                            >
                                                {item.year}
                                            </label>
                                        </div>

                                        <Typography
                                            variant="h3"
                                            style={{ color: "#5DC38C" }}
                                        >
                                            {formatter({
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                            }).format(
                                                item.total_emission_savings
                                            )}
                                        </Typography>
                                    </div>
                                )
                            )}
                        </CardContent>
                    </Box>
                </Card>
            </Grid>

            <Grid item container spacing={4}>
                <Grid item xs={6}>
                    <Card>
                        <CardHeader
                            title={translate("ra.resources.weighing_tickets")}
                            action={CardHeaderButton({
                                url: "/weighing-tickets",
                                label: translate(
                                    "ra.actions.all_weighing_tickets"
                                ),
                            })}
                        />
                        {overviewData.weighing_tickets_data &&
                        overviewData.weighing_tickets_data.length > 0 ? (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {translate("ra.fields.date")}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate(
                                                "ra.fields.weighing_ticket_number"
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate("ra.fields.weight")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {overviewData.weighing_tickets_data.map(
                                        (ticket) => (
                                            <TableRow
                                                className={classes.tableRow}
                                                key={ticket.id}
                                                onClick={() =>
                                                    history.push(
                                                        `/weighing-tickets/${ticket.id}`
                                                    )
                                                }
                                            >
                                                <TableCell>
                                                    {ticket.date}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {ticket.number}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {ticket.total_weight} kg
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        ) : (
                            <EmptyList
                                resourceName={translate(
                                    "ra.no_resource_found.weighing_tickets"
                                )}
                            />
                        )}
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <CardHeader
                            title={translate("ra.resources.invoices")}
                            action={CardHeaderButton({
                                url: "/invoices",
                                label: translate("ra.actions.all_invoices"),
                            })}
                        />
                        {overviewData.invoices_data &&
                        overviewData.invoices_data.length > 0 ? (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {translate("ra.fields.date")}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate(
                                                "ra.fields.invoice_number"
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {translate("ra.fields.total_price")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {overviewData?.invoices_data && (
                                    <TableBody>
                                        {overviewData.invoices_data.map(
                                            (invoice) => (
                                                <TableRow
                                                    className={classes.tableRow}
                                                    key={invoice.id}
                                                    onClick={() =>
                                                        history.push(
                                                            `/invoices/${invoice.id}`
                                                        )
                                                    }
                                                >
                                                    <TableCell>
                                                        {invoice.date}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {invoice.number}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Price>
                                                            {
                                                                invoice.total_price
                                                            }
                                                        </Price>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                )}
                            </Table>
                        ) : (
                            <EmptyList
                                resourceName={translate(
                                    "ra.no_resource_found.invoices"
                                )}
                            />
                        )}
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DashboardCustomer;

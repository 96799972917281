import axios from "axios";

const PROVIDER_URI = process.env.REACT_APP_DATA_PROVIDER_URI || null;

export default {
    async login({ domain, email, password }) {
        // const corsRequest = new Request(process.env.REACT_APP_AUTH_CORS_URI, {
        //     method: 'GET',
        //     headers: new Headers({ 'Content-Type': 'application/json' }),
        // });
        // const loginRequest = new Request(process.env.REACT_APP_AUTH_LOGIN_URI, {
        //     method: 'POST',
        //     body: JSON.stringify({ username, password }),
        //     headers: new Headers({ 'Content-Type': 'application/json' }),
        // });

        try {
            const corsResponse = await axios.get(
                PROVIDER_URI + `/auth/csrf-cookie`
            );
            if (corsResponse.status < 200 || corsResponse.status >= 300)
                throw new Error(corsResponse.statusText);

            const response = await axios.post(PROVIDER_URI + `/auth/login`, {
                domain,
                email,
                password,
            });
            if (response.status < 200 || response.status >= 300)
                throw new Error(response.statusText);

            const auth = response.data;
            localStorage.setItem("rk_auth", JSON.stringify(auth));
        } catch (e) {
            throw new Error(
                e.response?.data?.errors?.message ||
                    "Kan niet inloggen, probeer het later opnieuw."
            );
        }
    },
    logout() {
        localStorage.removeItem("rk_auth");
        return Promise.resolve();
    },
    checkError(errorProps) {
        const { response: error } = errorProps;

        if (error.status === 401 || error.status === 403) {
            localStorage.removeItem("rk_auth");
            return Promise.resolve("guest");
        }
        return Promise.resolve("guest");
    },
    checkAuth() {
        return localStorage.getItem("rk_auth")
            ? Promise.resolve()
            : Promise.reject();
    },
    async getPermissions() {
        const data = JSON.parse(localStorage.getItem("rk_auth"));
        if (!(data && data.token)) return Promise.resolve("guest");

        try {
            const response = await axios.get(
                PROVIDER_URI + `/auth/permissions`,
                {
                    headers: { "X-Authorization": `Bearer ${data.token}` },
                }
            );
            return Promise.resolve(response.data.permissions);
        } catch (e) {
            return this.checkError(e);
        }
    },
    getIdentity: () => {
        try {
            const data = JSON.parse(localStorage.getItem("rk_auth"));
            return Promise.resolve({
                email: data.email,
                full_name: data.full_name,
                first_name: data.first_name,
                last_name: data.last_name,
            });
        } catch (error) {
            return Promise.reject(error);
        }
    },
};
